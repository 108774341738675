<template>
	<div class="page container">
		<div>
			<activity
				:lessonId="getLessonId"
				:type="getType"
				:activityId="getActivityId"
				:cancelRoute="getActivityRoute"
				:successRoute="getActivityRoute"
			/>
		</div>
	</div>
</template>

<script>
	import Activity     from '@/components/activities/edit/Activity';
	import actionClick  from '@/mixins/actionClick';
	import routeParams  from '@/mixins/routeParams';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Edit activity'
		},
		components: {
			Activity
		},
		mixins: [actionClick, routeParams],
		computed: {
			getType () {
				return this.$route.query.type;
			},
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getCourseRoute ||
						!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
			},
			getActivitiesRoute () {
				if (!this.getLessonRoute) {
					return false;
				}
				return `${this.getLessonRoute}/activities`;
			},
			getActivityRoute () {
				if (!this.getActivitiesRoute ||
						!this.getActivityId) {
					return false;
				}
				return `${this.getActivitiesRoute}/${this.getActivityId}`;
			}
		},
		created () {}
	};

</script>
